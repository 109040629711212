<template>
  <div class="mt-5">
    <vue-headful
      title="Settings Plan & Billing | Octoa"
    />
    <v-inner-header
      redirect="/settings/plan-and-billing"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full px-2">
        <div class="bg-white h-full mx-auto max-w-md px-8 py-8 border-grey-lighter border rounded">
          <h2 class="font-bold mb-2 text-center">
            Update your payment method
          </h2>
          <div class="text-xl mt-2">
            <div class="text-center text-grey-darker">
              <span class="text-lg "> Enter your payment info below</span>
            </div>

            <div class="w-full flex flex-wrap mt-8">
              <div class="w-full text-base font-bold text-black">
                Credit card details
              </div>
              <div class="mollie flex flex-wrap w-full mt-3">
                <div
                  id="card-holder-group"
                  class="w-full  form-group form-group--cardHolder"
                >
                  <label
                    class="label"
                    for="card-holder"
                  >Card holder</label>
                  <div id="card-holder" />
                  <div
                    id="card-holder-error"
                    class="field-error"
                    role="alert"
                  />
                </div>

                <div class="mt-3   w-full form-group form-group--cardNumber">
                  <label
                    class="label"
                    for="card-number"
                  >Card number</label>
                  <div id="card-number" />
                  <div
                    id="card-number-error"
                    class="field-error"
                    role="alert"
                  />
                </div>
                <div class="w-1/2 mt-3  form-group form-group--expiryDate">
                  <label
                    class="label"
                    for="expiry-date"
                  >MM / YY</label>
                  <div
                    id="expiry-date"
                    class="pr-4"
                  />
                  <div
                    id="expiry-date-error"
                    class="field-error"
                    role="alert"
                  />
                </div>
                <div class="w-1/2 mt-3 form-group form-group--verificationCode">
                  <label
                    class="label"
                    for="verification-code"
                  >CVV</label>
                  <div id="verification-code" />
                  <div
                    id="verification-code-error"
                    class="field-error"
                    role="alert"
                  />
                </div>
              </div>
              <div class="mt-5 w-full text-base font-bold text-black">
                Billing details
              </div>
              <div class="w-full flex flex-wrap mt-3">
                <div class="w-full">
                  <input
                    id="name"
                    ref="name"
                    v-model="form.name"
                    v-validate="{ required: true }"
                    class="w-full"
                    type="text"
                    name="name"
                    placeholder="Name"
                  >
                  <span
                    v-if="errors.has('name')"
                    class="error"
                  >{{ errors.first('name') }}</span>
                </div>
                <div class="w-full mt-3">
                  <input
                    id="addres"
                    ref="address"
                    v-model="form.address"
                    v-validate="{ required: true }"
                    name="address"
                    class="w-full"
                    type="text"
                    placeholder="Address"
                  >
                  <span
                    v-if="errors.has('address')"
                    class="error"
                  >{{ errors.first('address') }}</span>
                </div>
                <div class="w-1/2 mt-3 pr-4">
                  <input
                    id="postal_code"
                    ref="postal_code"
                    v-model="form.postal_code"
                    v-validate="{ required: true }"
                    class="w-full"
                    name="postal"
                    type="text"
                    placeholder="Postal code"
                  >
                  <span
                    v-if="errors.has('postal')"
                    class="error"
                  >{{ errors.first('postal') }}</span>
                </div>
                <div class="w-1/2 mt-3">
                  <input
                    id="city"
                    ref="city"
                    v-model="form.city"
                    v-validate="{ required: true }"
                    class="w-full"
                    name="city"
                    type="text"
                    placeholder="City"
                  >
                  <span
                    v-if="errors.has('city')"
                    class="error"
                  >{{ errors.first('city') }}</span>
                </div>
                <div class="w-full mt-3">
                  <div class="select-container w-full">
                    <select
                      v-model="form.country"
                      v-validate="'required'"
                      name="country"
                      :tab-index="0"
                      @change="countryChange"
                    >
                      <option value="">
                        Select a country
                      </option>
                      <option
                        v-for="(item, index) in countriesList"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <span
                    v-if="errors.has('country')"
                    class="error"
                  >{{ errors.first('country') }}</span>
                </div>
                <div class="w-full mt-3">
                  <input
                    id="vat"
                    ref="vat"
                    v-model="form.vat"
                    class="w-full"
                    name="vat"
                    type="text"
                    placeholder="VAT number (optional)"
                    @input="onCheckVat"
                  >
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!paymentLoading"
            class="green-btn mt-5 w-full rounded"
            @click="upgrade"
          >
            Update payment methods
          </button>

          <div
            v-if="paymentLoading"
            class="text-center mt-5"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="35"
              class="text-center"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import mollie from '@/mollie'
import { debounce } from '@/utils/helpers'

  export default {
    name: 'CreditcardPayment',
    data(){
      return {
        loading: false,
        paymentLoading: false,
        priceText: '120',
        pricePeriod: 'year',
        user: auth.user(),
        vatNumberValid: false,
        timezone: '',
        countriesList: {},
        form:{
          plan: 'annually',
          token: '',
          name: '',
          address: '',
          postal_code: '',
          city: '',
          country: '',
          vat: '',
          includeVat: false,
        }
      }
    },
    created(){

    },
    beforeDestroy(){
      mollie.cardHolder.unmount('#card-holder')
      mollie.cardNumber.unmount('#card-number')
      mollie.expiryDate.unmount('#expiry-date')
      mollie.verificationCode.unmount('#verification-code')
    },
    async mounted(){

      if(!this.user.me.company.is_subscription_active){
        this.$router.push('/settings/plan-and-billing/')
      }

      await this.refresh()
      await this.getCountriesList()

      this.form.name = this.user.company.billing_name
      this.form.address = this.user.company.billing_address
      this.form.postal_code = this.user.company.billing_postal_code
      this.form.city = this.user.company.billing_city
      this.form.country = this.user.company.billing_country
      this.form.plan = this.$route.params.plan
      this.form.vat = this.user.company.vat_number
      this.timezone = this.user.companySettings.timezone

      debounce(async () => {
        this.checkVat(this.form.country)
      }, 500)()

      mollie.cardHolder.mount('#card-holder')
      const cardHolderError = document.getElementById('card-holder-error')
      mollie.cardHolder.addEventListener('change', event => {
        if (event.error && event.touched) {
          cardHolderError.textContent = event.error
        } else {
          cardHolderError.textContent = ''
        }
      })

      mollie.cardNumber.mount('#card-number')
      const cardNumberError = document.getElementById('card-number-error')
      mollie.cardNumber.addEventListener('change', event => {
        if (event.error && event.touched) {
          cardNumberError.textContent = event.error
        } else {
          cardNumberError.textContent = ''
        }
      })


      mollie.expiryDate.mount('#expiry-date')
      const expiryDateError = document.getElementById('expiry-date-error')

      mollie.expiryDate.addEventListener('change', event => {
        if (event.error && event.touched) {
          expiryDateError.textContent = event.error
        } else {
          expiryDateError.textContent = ''
        }
      })

      mollie.verificationCode.mount('#verification-code')
      const verificationCodeError = document.getElementById('verification-code-error')

      mollie.verificationCode.addEventListener('change', event => {
        if (event.error && event.touched) {
          verificationCodeError.textContent = event.error
        } else {
          verificationCodeError.textContent = ''
        }
      })

      function toggleFieldDirtyClass(fieldName, dirty) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('is-dirty', dirty)
      }

      function toggleFieldFocusClass(fieldName, hasFocus) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('has-focus', hasFocus)
      }

      mollie.cardHolder.addEventListener('change', event => toggleFieldDirtyClass('card-holder', event.dirty))
      mollie.cardHolder.addEventListener('focus', () => toggleFieldFocusClass('card-holder', true))
      mollie.cardHolder.addEventListener('blur', () => toggleFieldFocusClass('card-holder', false))

      mollie.cardNumber.addEventListener('change', event => toggleFieldDirtyClass('card-number', event.dirty))
      mollie.cardNumber.addEventListener('focus', () => toggleFieldFocusClass('card-number', true))
      mollie.cardNumber.addEventListener('blur', () => toggleFieldFocusClass('card-number', false))

      mollie.expiryDate.addEventListener('change', event => toggleFieldDirtyClass('expiry-date', event.dirty))
      mollie.expiryDate.addEventListener('focus', () => toggleFieldFocusClass('expiry-date', true))
      mollie.expiryDate.addEventListener('blur', () => toggleFieldFocusClass('expiry-date', false))

      mollie.verificationCode.addEventListener('change', event => toggleFieldDirtyClass('verification-code', event.dirty))
      mollie.verificationCode.addEventListener('focus', () => toggleFieldFocusClass('verification-code', true))
      mollie.verificationCode.addEventListener('blur', () => toggleFieldFocusClass('verification-code', false))

      this.loading = false
    },
    methods: {
      onCheckVat(){
        debounce(async () => {
          this.checkVat(this.form.country)
        }, 1000)()
      },
      checkVat(countryName){
        let that = this
        this.vatNumberValid = false

        const http = new XMLHttpRequest()
        const url = `https://apilayer.net/api/validate?access_key=0e1bcc6d3f622b4ed1111f86e61db3c9&vat_number=${this.form.vat}&format=1`
        http.open('GET', url)
        http.send()

        http.onreadystatechange = function(data){
          if(data.target.readyState == 4 && data.target.status == 200){
            let response = JSON.parse(data.target.response)
            that.vatNumberValid = response.valid
          }

          that.form.includeVat = false
          that.countriesList.forEach(country => {
            if(country.name == that.form.country){
              if(country.name == 'Netherlands' || (country.is_european_union && !that.vatNumberValid)){
                that.form.includeVat = true
              }
            }
          })
        }
      },
      async countryChange(event){
        debounce(async () => {
          this.checkVat(event.target.value)
        }, 300)()
      },
      async refresh() {
        this.loading = false
      },
      async getCountriesList() {
        try {
          const { data } = await this.$api.get('country').all()
          this.countriesList = data.countries
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      },
      async upgrade(){
        const { token, error } = await mollie.orgin.createToken()
        this.form.token = token

        this.$validator.validate().then(result => {
          if(result){
            this.submit()
          }
        })
      },
      async submit(){

        if(this.form.token !== undefined){
          this.paymentLoading = true
          this.$toasted.clear()

          try {
            const { data } =   await this.$api.get('subscription').updatePayment(this.user.company.id, this.form)

            await this.$store.dispatch('auth/refreshUser')

            if(data._links.checkout.href){
              window.location.href = data._links.checkout.href
            } else{
              await this.refresh()
              this.$router.push('/settings/plan-and-billing')
            }
          } catch (e) {
            this.$toasted.global.api_error(e)
          }

          this.paymentLoading = false
        }
      },
    },
  }
</script>
